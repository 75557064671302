/* media query for iPads, Tablets */

@media only screen and (min-width:481px) and (max-width:767px) {

  /* General changes in headings and paragraphs */
  
  h1{
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
  }
  p{
    font-size: 16px;
  }

  /* changing the style of the scroll to top button */

  .scroll_top{
    right: 0px;
    bottom: 60px;
  }

  .scroll_top:hover{
    opacity: 0.8;
  }

  /* for this range of screen size, the sidebar will be positioned fixed at the top */

  .sidebar{
    position: fixed;
    top:0;
    left:0;
    overflow: hidden;
    height: 60px;
    width: 100%;
    display: block;
    padding:15px;
    padding-left: 8px;
    padding-right: 0;
  }

  /* creating the navbar at the top of the screen by aligning elements */

  #top_navbar{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* removing the image */

  img{
   display: none;
  }
  
  /* Styling navbar elements */

  .navbar li{
    padding-right: 12px;
    font-size: 16px;
  }

  .main_content{
    margin-left: 0px;
    padding-left:35px;
    padding-right: 35px;
  }

  /* Generalized changes in every component */

  .about_section, 
  .education_section, 
  .experience_section, 
  .awards_section, 
  .news_section, 
  .contact_section{
    margin-bottom: 80px;
    margin-right: 0px;
  }

  /* About component */

  .about_icons{
    font-size: 40px;
    margin-right: 30px;
    padding:10px;
  }

  .short_intro{
    font-size:22px;
    line-height: 1.4;
    margin-top: 10px;
  }  
  .resume{
    font-size: 20px;
  }
  /* Education component */

  /* stacking the flex items in education component */

  .edu_flex{
    flex-direction: column;
  }
  .edu_flex h3{
    font-size: 20px;
  }
  .edu_icon{
    margin-right: 0px;
  }
  h3 + p{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .edu_content{
    margin-left: 0px;
  }
  .edu_content > p{
    font-size: 18px;
    margin-bottom: 5px;
  }
  .edu_list{
    margin-left: 17px;
    font-size: 16px;
    padding-bottom: 60px;
  }
  .course_content{
    margin-left: 0px;
    line-height: 1.5;
    padding-bottom: 70px;
  }
  .course_content h3{
    margin-bottom: 18px;
  }
  /* Experience component */

  .card{
    padding:20px;
    margin-right: 0px;
    margin-bottom: 80px;
  }
  .card_grid{
    grid-template-columns: 3fr 2fr;
    gap: 0px 30px;
  }
  .position{
    font-size: 26px;
    line-height: 1.4;
  }
  .location, 
  .dates,
  .company
  {
    font-size: 16px;
    line-height: 1.4;
    
  }
  .card_icon{
    margin-right: 5px;
    font-size: 16px;
  }

  /* News component */

  .news_grid{
    grid-template-columns: 3fr 6fr;
    gap: 0px 20px;
  }

  /* Contact component */

  .contact_section{
    padding-bottom: 0px;
  }
  .contact_content{
    padding: 0px;
  }
}