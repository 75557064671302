/* Media query for small screens, laptops */

@media only screen and (min-width:768px) and (max-width:1024px){

  /* changing the style of the scroll to top button */

  .scroll_top{
    right: 6px;
    bottom: 30px;
  }

  .scroll_top:hover{
    opacity: 0.8;
  }

  /* Sidebar component */

  /* reducing the width of sidebar */

  

  .sidebar{
    width:200px;
  }

  /* reducing the image size */

  img{
    height: 160px;
    width:160px;
  }

  /* general style changes in the content */

  .main_content{
    margin-left: 200px;
  }
  h1{
    text-align: center;
    font-size: 50px;
    margin-bottom: 40px;
  }

  /* general style changes in every component */

  .about_section, 
  .education_section, 
  .experience_section, 
  .awards_section, 
  .news_section, 
  .contact_section{
    margin-bottom: 80px;
    margin-right: 0px;
  }

  /* About component */

  .about_section{
    padding-bottom: 70px;
  }

  .short_intro{
    font-size: 24px;
    line-height: 1.4;
    margin-top: 10px;
  }  

  .about_icons{
    font-size: 45px;
    margin-right: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding:10px;
  }

  .resume{
    font-size: 22px;
  }

  /* Education component */

  /* stacking the flex items in education component */

  .edu_flex{
    flex-direction: column;
  }
  .edu_flex h3{
    font-size: 22px;
  }
  .edu_icon{
    margin-right: 5px;
  }
  h3 + p{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .edu_content{
    margin-left: 0px;
  }
  .edu_content > p{
    font-size: 20px;
    margin-bottom: 5px;
  }
  .edu_list{
    margin-left: 17px;
    font-size: 18px;
    padding-bottom: 40px;
  }

  .course_content{
    margin-left: 0px;
    line-height: 1.5;
    padding-bottom: 50px;
  }
  .course_content h3{
    margin-bottom: 20px;
  }
  .course_content p{
    font-size: 18px;
  }

  /* Experience component */

  .card{
    padding:20px;
    margin-right: 0px;
    margin-bottom: 80px;
  }
  .card_grid{
    grid-template-columns: 4fr 2fr;
    gap: 0px 30px;
  }
  .position{
    font-size: 28px;
    line-height: 1.4;
  }
  .location, 
  .dates,
  .company
  {
    font-size: 17px;
    line-height: 1.4;
    
  }
  .card_icon{
    margin-right: 5px;
    font-size: 17px;
  }

  /* News component */

  .news_section
  {
    padding-bottom: 60px;
  }
  .awards_section{
    padding-bottom: 100px;
  }
  .news_grid{
    grid-template-columns: 3fr 6fr;
    gap: 0px 20px;
  }

  /* Contact component */

  .contact_content{
    padding: 0px;
  }
  .contact_section{
    margin-top: 100px;
    padding-bottom: 0px;
  }
}