/* media query for mobile devices */

@media only screen and (max-width:480px){

  /* for smooth scrolling */

  html, body{
    scroll-behavior: smooth;
  }
  
  /* changing the general styling of all heading and paragraphs */

  h1{
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
    line-height: 1.4;
  }


  /* hiding the scroll to top button */

  .scroll_top{
    display: none !important;
  }
  

  /* for this range of screen size, the sidebar will be positioned fixed at the top */

  .sidebar{
    display: none;
  }

  /* mobile navbar fixed at the top of the website */

  .mobile_navbar{
    background-color: #6c757d;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
  }
  .mobile_navbar_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
  }
  .mobile_navbar p{
    display: block;
    font-size: 20px;
    z-index: 100;
    color: #fff;
    font-weight: 100;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 15px;
    line-height: 1.3;
  }

  /* the 3 horizontal lines make up the hamburger menu */

  .hamburger_icon{
    background-color:#BD5D38;
    z-index: 100;
    margin-right: 20px;
  }

  /* styling the links in the vertical navbar */

  .mobile_navbar li{
    margin-bottom: 20px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'JetBrains Mono', monospace;
    cursor: pointer;
    margin-left: 20px;
    color: rgba(255,255,255,0.5);
  }
  .mobile_navbar li:hover{
    color: #fff;
  }

  

  /* hiding the image */

  img{
   display: none;
  }


  .main_content{
    margin-bottom: 100px;
    margin-left: 0px;
    padding-left:25px;
    padding-right: 25px;
  }

  /* Generalized changes in every component */

  .about_section, 
  .education_section, 
  .experience_section, 
  .awards_section, 
  .news_section, 
  .contact_section{
    margin-bottom: 80px;
    margin-right: 0px;
  }

  .about_section h1{
    margin-top: 30px;
    margin-bottom: 25px;
  }

  /* About component */

  .about_section p{
    line-height: 1.6;
    font-size: 18px;
  }  

  .about_icons{
    font-size: 46px;
    margin-right: 30px;
    padding:10px;
  }

  .short_intro{
    font-size:22px !important;
    line-height: 1.5 !important;
    margin-top: 10px;
  }  
  .additional_info{
    margin-bottom: 10px;
  }
  .resume{
    font-size: 20px;
  }
  /* Education component */

  /* stacking the flex items in education component */

  .edu_flex{
    flex-direction: column;
  }
  .edu_flex h3{
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  .edu_icon{
    margin-right: 0px;
  }
  h3 + p{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .edu_content{
    margin-left: 0px;
  }
  .edu_content > p{
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.4;
  }
  .edu_list{
    margin-left: 17px;
    font-size: 18px;
    padding-bottom: 60px;
  }

  .course_content{
    margin-left: 0px;
    line-height: 1.6;
    padding-bottom: 40px;
  }

  /* Experience component */

  .card{
    /* padding:20px; */
    padding: 20px 15px 20px 15px;
    margin-right: 0px;
    margin-bottom: 80px;
  }

  /* stacking divs */

  .card_grid{
    display: block;
  }
  .position_description{
    line-height: 1.6;
  }
  .position_description li{
    margin-bottom: 10px;
  }
  .position{
    font-size: 26px;
    line-height: 1.4;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  .location, 
  .dates,
  .company
  {
    font-size: 18px;
    margin-bottom: -7px;
    line-height: 1.5;
    
  }
  .card_icon{
    margin-right: 5px;
    font-size: 16px;
  }

  /* News component */

  .news_grid{
    grid-template-columns: 3fr 5fr;
    gap: 0px 20px;
  }
  .news_section p{
    line-height: 1.6 !important;
  }

  /* Contact component */

  .contact_section{
    padding-bottom: 0px;
    margin-bottom: 0;
  }
  .contact_content{
    padding: 0px;
  }
}  
